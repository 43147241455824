(function ($) {
  $(document).ready(function () {

    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on('click', function (e) {

      var $href = $(this).attr('href'),
        $tgt = $($href == "#" || $href == "" ? 'html' : $href),
        $pos = $tgt.offset().top,
        $correctH = parseInt($('.header_top').outerHeight() + 10);
      $("html, body").animate({
        scrollTop: $pos - $correctH
      }, 500);
      e.preventDefault();

    });



    var mql = window.matchMedia("(min-width : 768px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }


    // globalnavi 固定
    var nav = $('.header_top'),
      offset = nav.offset();
    $(window).scroll(function () {
      if ($(window).scrollTop() > /*offset.top +*/ window.innerHeight / 5) {
        nav.addClass('fixed');
      } else {
        nav.removeClass('fixed');
      }
    });





    $('.top_slider').slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 1000
    });
    
    
    $('.top_yutalana_slider').slick({
      autoplay: true,
      autoplaySpeed: 2500,
      speed: 800,
      dots: true,
      arrows: true,
      centerMode: true,
      centerPadding: '25%',
      prevArrow: '<span class="prev"></span>',
      nextArrow: '<span class="next"></span>',
      responsive: [
        {
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            centerPadding: '10%',
          }
        }
      ]
    });





    // gmap iframe
    $('.gmap').click(function () {
      $('.gmap iframe').css("pointer-events", "auto");
    });

  });



  
  
})(jQuery);

window.addEventListener('load',function(){
  //document.getElementById('loading').classList.add('finished');
})



window.addEventListener('DOMContentLoaded', function () {
  
  //set noopener
  
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links,function(link){
    
    if(link.hasAttribute('rel') === false){
      link.setAttribute('rel','noopener');
    }

  })
  
  
  
  //category 各記事1枚目画像消す
  
  var articles = document.querySelectorAll('.post_text');
  
  [].forEach.call(articles,function(article){
    
    var firstImg = article.querySelector('img');
    
    if(firstImg !== null){
      var removeTgt = firstImg.parentNode.parentNode;
      article.removeChild(removeTgt);
    }
  })


  //hmb

  


  //var snsList = document.getElementById('sns_list').innerHTML;
  
  //var addLink = [snsList];
  
  /*addLink.forEach.call(addLink,function(elm){
    var li = document.createElement('li');
    li.classList.add('m_correct')
    li.innerHTML = elm;
    document.querySelector('#menu .menu_wrap').appendChild(li);
  })*/

  
  
  var $toggle = document.getElementById('toggle');
  var $menuBg = document.getElementById('menu_bg');
  var $gnav = document.getElementById('site_navigation');
  var $menuTrigger = document.querySelector('.menu-trigger');
  var $menuLists = document.querySelectorAll('#menu li a');
  var $body = document.querySelector('body');
  toggle.addEventListener('click', function (e) {
    e.preventDefault();
    this.classList.toggle('active');
    $body.classList.toggle('menuopen');
    $menuBg.classList.toggle('menuopen');
    $gnav.classList.toggle('active');
    $menuTrigger.classList.toggle('triggeractive');
  });

  $gnav.addEventListener('click',function(e){
    e.preventDefault();
    this.classList.remove('active');
    $body.classList.remove('menuopen');
    $menuBg.classList.remove('menuopen');
    $gnav.classList.remove('active');
    //$headerFixed.classList.toggle('open');
    $menuTrigger.classList.remove('triggeractive');
  });
  
  
  
  [].forEach.call($menuLists,function(list){
    list.addEventListener('click',function(e){
      e.stopPropagation();
      
    });
    
  });
  
  
  
  //top_topics addclass
  
  var topTopicsImg = document.querySelectorAll('.top_news_list_li_img img');
  
  if(topTopicsImg !== null){
    [].forEach.call(topTopicsImg,function(elm){
      elm.classList.add('ofi');
    })
  }
  
  //top_topics textchange
  
  var topNewsListLiDataCatDiary = document.querySelectorAll('.top_news_list_li_data_cat.diary');
  
  if(topNewsListLiDataCatDiary !== null){
    [].forEach.call(topNewsListLiDataCatDiary,function(elm){
      elm.textContent = 'COMPASS'
    })
  }
  
  
  //staff
  
  var staffImg = document.querySelectorAll('.staff_img img');
  
  if(staffImg !== null){
    [].forEach.call(staffImg,function(elm){
      elm.classList.add('ofi');
    })
    
    window.addEventListener('load',function(){
      [].forEach.call(staffImg,function(elm){
        var imgWidth = elm.clientWidth;
        elm.style.height = imgWidth + 'px';
      })
    })
    
    
    
    var timer;
    
    window.addEventListener('resize',function(){
      
      clearTimeout(timer);
      
      timer = setTimeout(function(){
        [].forEach.call(staffImg,function(elm){
          var imgWidth = elm.clientWidth;
          elm.style.height = imgWidth + 'px';
        })
      },200)
      
      
    })
    
  }//staff
  
  
  //topics filter
  
  /*var topicsList = document.querySelector('.topics_list');
  
  if(topicsList !== null){
    var list = new Muuri('.topics_list');
  function select(id,tgt){

    id.addEventListener('click',function(){
      list.filter(function(item){
        var element = item.getElement();
        return element.getAttribute('data-category').includes(tgt);
      });
    })

  }

  var all = document.querySelector('.select_category #all')

  all.addEventListener('click',function(){
    list.filter(function(item){
      var element = item.getElement();
      return element.getAttribute('data-category');
    });
  })


    var information = document.querySelector('.select_category #information')
    var blog = document.querySelector('.select_category #blog')
    var diary = document.querySelector('.select_category #diary')

select(information,"information");
  select(blog,"blog");
  select(diary,"diary");
    
  }*///topics
  
  
  
  var information = document.querySelector('.select_category #information')
  var blog = document.querySelector('.select_category #blog')
  var diary = document.querySelector('.select_category #diary')
  
  
  
  //topics addclass
  
  var topicsCat = document.querySelectorAll('.post-categories li a');

  if(topicsCat){
    [].forEach.call(topicsCat,function(elm){
      var catText = elm.textContent;

      if(catText.includes('INFO') === true){
        elm.classList.add('info');
      }else if(catText.includes('BLOG') === true){
        elm.classList.add('blog');
      }else{
        elm.classList.add('other');
      }

    })
  }
  
  
  
  
  
  
  
  objectFitImages('.ofi');
  
});



//for youtube loop

window.addEventListener('load',function(){
  
  
  

  const movie = document.querySelectorAll('.youtube iframe, .wp-block-embed__wrapper iframe');
  
  
  

  [].forEach.call(movie,function(elm){
    const movieSrc = elm.getAttribute('src');
    const removedParam = movieSrc.replace(/\?.*$/,"");
    const movieId = removedParam.indexOf('embed/');
    var movieID2 = movieSrc.substr(movieId + 6 , 11);

    elm.src = `${removedParam}?loop=1&playlist=${movieID2}`;
    
  });
  
  const loading = document.querySelector('#loading');

  loading.classList.add('finished');

})


